.messenger {
  display: grid;
  width: 100%;
  height: 100vh;
  background: #dde2f6;

  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.messenger-mobile {
  display: grid;
  width: 100%;
  height: 100vh;
  background: #dde2f6;

  grid-template-columns: auto auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
}

.sidebar {
  /* background: white; */
  grid-row-start: 1;
  grid-row-end: span 4;
  overflow-x: hidden;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}

.label-color-complaint-keterlambatan {
  background-color: #e57373;
  color: #ffffff;
}

.label-color-register-apps {
  background-color: magenta;
  color: #ffffff;
}

.label-color-complaint-produk-tidak-sesuai {
  background-color: #f06292;
  color: #ffffff;
}

.label-color-complaint-layu-rusak {
  background-color: #ba68c8;
  color: #ffffff;
}

.label-color-complaint-report {
  background-color: #7986cb;
  color: #ffffff;
}

.label-color-report {
  background-color: #ff8a65;
  color: #ffffff;
}

.label-color-konfirmasi-pesanan {
  background-color: #64b5f6;
  color: #ffffff;
}

.label-color-lain-lain {
  background-color: #4db6ac;
  color: #ffffff;
}

.label-color-complain-pesanan-tidak-dibuatkan {
  background-color: #a1887f;
  color: #ffffff;
}

.display-none{
  display: none;
}
