.message-list-viewer-container {
  padding: 10px;
  padding-bottom: 70px;
  height: 87vh;
  overflow-y: visible;
  flex-direction: column-reverse;
}

.fab{
  position: 'absolute';
}