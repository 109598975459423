.message-list-container {
  padding: 10px ;
  /* padding-bottom: 10px; */
  height: 85vh;
  /* height: 100%; */
  overflow-y: scroll;
  flex-direction: column-reverse;
}

.fab{
  position: 'absolute';
}