.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.mine .bubble-container .indicator {
  display: block;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  /* background: #304ffe; */
  /* background: #5e9a15; */
  background: #a1b3d4;
  /* background: #f38fb5; */
  padding: 3px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #000000;
}

.message.mine .bubble-container .bubble {
  background: #bfd2db;
  /* color: rgb(247, 230, 230); */
  color: #000000;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.media-size {
  width: 250px;
}
