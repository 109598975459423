.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 3px 5px 3px 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.conversation-list-item:hover {
  background: #cbc8f3;
  cursor: pointer;
}

.selected{
    background: #f09fec;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  display: "flex";
  flex-direction: "row";
  align-items: center;
  align-content: space-between;
  width: 100%;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
  max-height: 20px;
  overflow-y: hidden;
}