.MuiDrawer-paperAnchorRight {
    width: 500px;
    left: auto;
    right: 0;
}

.MuiInput-root {
    position: relative;
    width: 90%;
    margin-left: 15px;
}