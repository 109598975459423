.compose {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0px 10px 2px 0;
  /* display: flex;
  flex-wrap: wrap; */
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  position: relative;
  /* width: calc(100% - 20px); */
  /* width: 78%; */
  bottom: 0px;
  /* border: solid 1px; */
  border-radius: 20px;
}

@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.compose-input {
  /* flex: 90%; */
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
  /* flex: 10%; */
}

.compose .toolbar-button:hover {
  color: #99999c;
}