.root{
    margin: 'auto';
}

.cardHeader {
    padding: 10 20;
}

.list {
    width: '100%';
    height: '80%';
    background-color: "#FF0000";
    overflow: 'auto';
}

.button {
    margin: 5 0;
}

.containerPadding {
    padding: 20
}